<template>
  <button class="scope-button">
    <i
      class="scope-button__icon"
      :class="[icon]"
    />
    <span class="scope-button__text">
      {{ label }}
    </span>
  </button>
</template>

<script lang="ts">
export default {
  name: 'ScopeButton',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'icon-building'
    }
  }
}
</script>

<style lang="scss" scoped>
.scope-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 $margin-mini;
  height: 36px;
  background-color: $button-secondary;
  border-radius: 8px;
  cursor: default;
  @include block-shadow;
  box-sizing: border-box;

  &__icon {
    color: $text-inverse;
    @include font-icon;
    @include font-size($font-size-intermediate);
    margin-right: 4px;
  }

  &__text {
    @include font-text;
    @include font-size($font-size-regular);
    color: $text-inverse;
    max-width: $scope-label-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
